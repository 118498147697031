var render = function () {
var this$1 = this;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('li',{staticClass:"d-flex align-items-center",attrs:{"draggable":""},on:{"mouseenter":function () {this$1.showEditIcon = true},"mouseleave":function () {this$1.showEditIcon = false},"dragstart":function($event){return _vm.startDrag($event, _vm.feed, _vm.feed.folderId)}}},[_c('div',{staticClass:"d-flex align-content-center",staticStyle:{"padding-left":"20px"}},[_c('img',{attrs:{"src":_vm.feed.iconUrl}}),_c('router-link',{attrs:{"to":{
            name: 'Feed',
            params:{
                feedId: _vm.feed.id,
                feedName: _vm.urlFriendlyTitle(),
                page: 1
            }
        }}},[_vm._v(_vm._s(_vm.feedName(_vm.feed)))]),_c('div',{staticClass:"d-flex align-items-center justify-content-end"},[(!_vm.showEditIcon)?_c('small',{staticClass:"side-content"},[_vm._v(_vm._s(_vm.getNotificationCount(_vm.feed.unreadArticles)))]):_c('svg',{staticClass:"side-content",attrs:{"version":"1.1","xmlns":"http://www.w3.org/2000/svg","xmlns:xlink":"http://www.w3.org/1999/xlink","width":"18","height":"18","viewBox":"0 0 64 64"},on:{"click":_vm.openOverlay}},[_c('path',{attrs:{"d":"M24 40l8-4 28-28-4-4-28 28-4 8zM18.081 54.194c-1.977-4.17-4.104-6.298-8.275-8.275l6.193-17.049 8-4.869 24-24h-12l-24 24-12 40 40-12 24-24v-12l-24 24-4.87 8z"}})])])],1)])}
var staticRenderFns = []

export { render, staticRenderFns }